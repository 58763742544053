import axios from "axios";
import { base_url } from "../../utils/baseUrl";
import { config } from "../../utils/axiosconfig";

const getAllPromotion = async () => {
  const response = await axios.get(`${base_url}promotion/`, config);

  return response.data;
};

const getPromotion = async (id) => {
  const response = await axios.get(`${base_url}promotion/${id}`, config);

  return response.data;
};

const promotionService = {
  getAllPromotion,
  getPromotion,
};

export default promotionService;
