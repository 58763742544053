import React from "react";
import "../styles/Promotion.scss";
import { getNews, getANews } from "../features/news/newsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import Carousel from "../components/Carousel";
import moment from "moment";

const New = () => {
  const dispatch = useDispatch();
  const { allNews, news, isLoading, isError, isSuccess } = useSelector(
    (state) => state?.news
  );
  const id = useLocation().pathname.split("/")[3];
  const [activePromotionImage, setActivePromotionImage] = useState(0);

  useEffect(() => {
    dispatch(getANews(id));
    dispatch(getNews());
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getANews(id));
  }, [id]);

  const nextPromotionImage = () => {
    setActivePromotionImage((prev) => {
      if (prev === news?.images?.length - 1) {
        return 0;
      } else {
        return prev + 1;
      }
    });
  };

  const prevPromotionImage = () => {
    setActivePromotionImage((prev) => {
      if (prev === 0) {
        return news?.images?.length - 1;
      } else {
        return prev - 1;
      }
    });
  };

  return (
    <div className="promotion page">
      <div className="promotion__container">
        <div className="promotion__images">
          <div
            className="buttons"
            style={
              news?.images?.length > 1
                ? { display: "flex" }
                : { display: "none" }
            }
          >
            <button onClick={prevPromotionImage} className="slide__button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={0.5}
                stroke="currentColor"
                className="w-12 h-12"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 19.5L8.25 12l7.5-7.5"
                />
              </svg>{" "}
            </button>
            <button onClick={nextPromotionImage} className="slide__button">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={0.5}
                stroke="currentColor"
                className="w-12 h-12"
                style={{ transform: "rotate(180deg)" }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 19.5L8.25 12l7.5-7.5"
                />
              </svg>{" "}
            </button>
          </div>

          {news?.images?.map((item, index) =>
            // check if item is an image or a video
            item?.includes("mp4") ? (
              <video
                key={index}
                className={index === activePromotionImage ? "active" : ""}
                autoPlay
                muted
                loop
                src={item}
              />
            ) : (
              <img
                key={index}
                className={index === activePromotionImage ? "active" : ""}
                src={item}
              />
            )
          )}
        </div>

        <div className="promotion__info">
          <h3>{news?.title}</h3>
          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: news?.content }}
          ></div>
          <div className="date">
            <span>
              {moment(news?.dateStart).format("DD.MM.YYYY")}
              {news?.dateEnd ? (
                <span> - {moment(news?.dateEnd).format("DD.MM.YYYY")}</span>
              ) : (
                ""
              )}
            </span>
          </div>
        </div>
      </div>

      <div className="cards ">
        <div className="card__title">
          <div className="card__title">
            <h2>Događaji</h2>
            <h4>Ostale novosti</h4>
          </div>
        </div>

        <Carousel items={allNews} type={"novosti"} />
      </div>
    </div>
  );
};

export default New;
