import React, { useEffect, useState } from "react";
import "../styles/Stores.scss";
import "../styles/Sections.scss";
import { Link } from "react-router-dom";
import { BsArrowRight, BsClock } from "react-icons/bs";
import { getStores } from "../features/stores/storeSlice";
import { useDispatch, useSelector } from "react-redux";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import Carousel from "../components/Carousel";
import { getPromotion } from "../features/promotions/promotionsSlice";
import MiniCarousel from "../components/MiniCarousel";
import { TfiLayoutGrid2, TfiViewListAlt } from "react-icons/tfi";
import Aos from "aos";
import "aos/dist/aos.css";
const Stores = () => {
  const [displayOption, setDisplayOption] = useState("grid");
  const dispatch = useDispatch();
  const { stores } = useSelector((state) => state?.store);
  const { allPromotions: promotions } = useSelector(
    (state) => state?.promotions
  );
  const [activeHeroItem, setActiveHeroItem] = useState(0);
  const nextSlide = () => {
    setActiveHeroItem((prev) => {
      if (prev === stores.length - 1) {
        return 0;
      } else {
        return prev + 1;
      }
    });
  };

  const prevSlide = () => {
    setActiveHeroItem((prev) => {
      if (prev === 0) {
        return stores.length - 1;
      } else {
        return prev - 1;
      }
    });
  };

  useEffect(() => {
    dispatch(getStores());
    dispatch(getPromotion());
    window.scrollTo(0, 0);
    Aos.init({ duration: 1500 });
  }, []);

  return (
    <div className="stores page">
      <div
        className="page__title"
        data-aos="fade-up"
        data-aos-duration="500"
        data-aos-once="false"
        data-aos-offset="300"
      >
        <h2>Brandovi</h2>
        <h4>Trgovine</h4>
        <p>Ovdje možete pronaći trgovine koje se nalaze u našem centru.</p>
      </div>
      <div className="stores__container">
        <div
          className="stores__boxes "
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-once="false"
          data-aos-offset="300"
        >
          {[...stores]
            ?.sort((a, b) => a.title.localeCompare(b.title))
            .map((item, index) => (
              <Link
                className="stores__item"
                to={`/sesvete/trgovine/${item?._id}`}
                key={index}
              >
                <div className="stores__item__front stores__item__side">
                  <img className="logo" src={item?.logo} alt={item?.title} />
                  <p className="title">{item?.title}</p>
                </div>

                <div className="stores__item__back stores__item__side">
                  <p className="title">{item?.title}</p>
                  <p className="workingHours">
                    <div className="working__hours">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={0.5}
                        stroke="currentColor"
                        className="w-6 h-6 icon"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>

                      <span>{item?.workingHours?.ponedjeljak}</span>
                    </div>
                  </p>
                </div>
              </Link>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Stores;
