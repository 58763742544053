import React, { useState, useEffect } from "react";
import "../styles/Header.scss";
import { Link, NavLink, useLocation } from "react-router-dom";
import { getAMall } from "../features/malls/mallSlice";
import { useDispatch, useSelector } from "react-redux";
import logo from "../assets/images/logo.png";
import { getStores } from "../features/stores/storeSlice";
import { getPromotion } from "../features/promotions/promotionsSlice";
import { getNews } from "../features/news/newsSlice";
import { BsList } from "react-icons/bs";
import { Context } from "../App";
import twitter from "../assets/images/twitter_logo.png";
import facebook from "../assets/images/facebook_logo.png";
import instagram from "../assets/images/instagram_logo.png";
import tiktok from "../assets/images/tiktok_logo.png";
import pinterest from "../assets/images/pinterest_logo.png";

const Header = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const mallLocation = location.pathname.split("/")[1];
  const { mall, isLoading, isError, isSuccess } = useSelector(
    (state) => state?.mall
  );
  const { stores } = useSelector((state) => state?.store);
  const { allPromotions: promotions } = useSelector(
    (state) => state?.promotions
  );
  const { allNews } = useSelector((state) => state?.news);
  const [isHeaderSticky, setIsHeaderSticky] = useState(false);
  const [search, setSearch] = useState("");

  //get the context
  const { mobileMenu, setMobileMenu } = React.useContext(Context);

  useEffect(() => {
    dispatch(getAMall(mallLocation));
    dispatch(getStores());
    dispatch(getPromotion());
    dispatch(getNews());

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsHeaderSticky(scrollPosition > 100);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`header${isHeaderSticky ? " sticky" : ""}`}>
      <div className="info__container">
        <div className="info__container__right">
          <p className="info__container__text">
            Radno vrijeme centra: 09:00 - 21:00
          </p>
          <div className="vertical__line"></div>
          <p className="info__container__text">
            144. brigade Hrvatske vojske 3-5, 10360, Sesvete
          </p>
          <div className="vertical__line"></div>

          <p className="info__container__text">E-mail: info@parkandshop.hr</p>
          <div className="vertical__line"></div>
          <p className="info__container__text"> Telefon: +385 95 9161 097</p>
        </div>

        <div className="header__socials ">
          <div className="hero__socials__container">
            <a
              href="https://www.facebook.com/profile.php?id=100087764918874"
              target="_blank"
              rel="noreferrer"
            >
              <img src={facebook} alt="facebook" />
            </a>
            <a
              href="https://www.instagram.com/park_shop_sesvete/?hl=hr"
              target="_blank"
              rel="noreferrer"
            >
              <img src={instagram} alt="instagram" />
            </a>
          </div>
        </div>
      </div>
      <div className="navbar">
        <button
          className="navbar__mobile__button"
          onClick={() => setMobileMenu(!mobileMenu)}
        >
          <BsList className="icon" />
        </button>

        <button
          className="navbar__mobile__search"
          onClick={() => setMobileMenu(!mobileMenu)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="icon"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
            />
          </svg>
        </button>

        <Link className="navbar__logo" to={`/${mallLocation}`}>
          <img src={mall?.logo || logo} alt="logo" />
        </Link>

        <div className="navbar__links">
          <div className="navbar__link__container">
            <NavLink
              to={`/${mallLocation} `}
              className="navbar__link"
              activeClassName="active"
            >
              Naslovnica
            </NavLink>
          </div>
          <div className="navbar__link__container">
            <NavLink
              to={`/${mallLocation}/trgovine`}
              className="navbar__link"
              activeClassName="active"
            >
              Trgovine
            </NavLink>

            <div className="navbar__link__dropdown">
              {stores?.map((store) => (
                <NavLink
                  className="navbar__link__dropdown__item"
                  to={`/${mallLocation}/trgovine/${store?._id}`}
                >
                  <NavLink
                    className="dropdown__link"
                    to={`/${mallLocation}/trgovine/${store?._id}`}
                  >
                    {store?.title}
                  </NavLink>
                  <NavLink
                    className="logo__container"
                    to={`/${mallLocation}/trgovine/${store?._id}`}
                  >
                    <img src={store?.logo} alt="logo" />
                  </NavLink>
                </NavLink>
              ))}
            </div>
          </div>
          <div className="navbar__link__container">
            <NavLink
              to={`/${mallLocation}/promocije`}
              className="navbar__link"
              activeClassName="active"
            >
              Promocije
            </NavLink>
          </div>

          <div className="navbar__link__container">
            <NavLink
              to={`/${mallLocation}/novosti`}
              className="navbar__link"
              activeClassName="active"
            >
              Novosti
            </NavLink>
          </div>
        </div>

        <div className="navbar__search">
          <input
            type="text"
            placeholder="Pretraži trgovine, novosti, promocije..."
            onChange={(e) => setSearch(e.target.value)}
          />
          <button className="navbar__search__button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1}
              stroke="currentColor"
              className="icon"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
              />
            </svg>{" "}
          </button>

          <div className="navbar__link__dropdown">
            <NavLink
              className="navbar__link__dropdown__item promotion title"
              to={`/${mallLocation}/trgovine`}
            >
              <NavLink
                className="dropdown__link"
                to={`/${mallLocation}/trgovine/`}
              >
                Trgovine
              </NavLink>
            </NavLink>
            {stores?.map((store) => {
              if (store?.title.toLowerCase().includes(search.toLowerCase()))
                return (
                  <NavLink
                    className="navbar__link__dropdown__item"
                    to={`/${mallLocation}/trgovine/${store?._id}`}
                  >
                    <NavLink
                      className="dropdown__link"
                      to={`/${mallLocation}/trgovine/${store?._id}`}
                    >
                      {store?.title}
                    </NavLink>
                    <NavLink
                      className="logo__container"
                      to={`/${mallLocation}/trgovine/${store?._id}`}
                    >
                      <img src={store?.logo} alt="logo" />
                    </NavLink>
                  </NavLink>
                );
            })}
            <NavLink
              className="navbar__link__dropdown__item promotion title"
              to={`/${mallLocation}/promocije`}
            >
              <NavLink
                className="dropdown__link"
                to={`/${mallLocation}/promocije/`}
              >
                Promocije
              </NavLink>
            </NavLink>
            {promotions?.map((promotion) => {
              if (promotion?.title.toLowerCase().includes(search.toLowerCase()))
                return (
                  <NavLink
                    className="navbar__link__dropdown__item promotion"
                    to={`/${mallLocation}/promocije/${promotion?._id}`}
                  >
                    <NavLink
                      className="dropdown__link"
                      to={`/${mallLocation}/promocije/${promotion?._id}`}
                    >
                      {promotion?.title}
                    </NavLink>
                    <NavLink
                      className="logo__container"
                      to={`/${mallLocation}/promocije/${promotion?.images[0]}`}
                    >
                      <img src={promotion?.logo} alt="logo" />
                    </NavLink>
                  </NavLink>
                );
            })}
            <NavLink
              className="navbar__link__dropdown__item promotion title"
              to={`/${mallLocation}/novosti`}
            >
              <NavLink
                className="dropdown__link"
                to={`/${mallLocation}/novosti/`}
              >
                Novosti
              </NavLink>
            </NavLink>
            {allNews?.map((news) => {
              if (news?.title.toLowerCase().includes(search.toLowerCase()))
                return (
                  <NavLink
                    className="navbar__link__dropdown__item promotion"
                    to={`/${mallLocation}/novosti/${news?._id}`}
                  >
                    <NavLink
                      className="dropdown__link"
                      to={`/${mallLocation}/novosti/${news?._id}`}
                    >
                      {news?.title}
                    </NavLink>
                    <NavLink
                      className="logo__container "
                      to={`/${mallLocation}/novosti/${news?._id}`}
                    >
                      {news?.images[0].includes(".mp4") ? (
                        <img src={news?.images[1]} alt="logo" />
                      ) : (
                        <img src={news?.images[0]} alt="logo" />
                      )}
                    </NavLink>
                  </NavLink>
                );
            })}
          </div>
        </div>
      </div>

      <div
        className={
          mobileMenu ? "navbar__mobile__menu active" : "navbar__mobile__menu"
        }
      >
        <button
          className="navbar__mobile__button"
          onClick={() => setMobileMenu(!mobileMenu)}
        ></button>

        <button className="x" onClick={() => setMobileMenu(!mobileMenu)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <div className="navbar__mobile__logo">
          <img src={mall?.logo || logo} alt="logo" />
        </div>
        <div className="navbar__mobile__links">
          <div className="navbar__mobile__link__container">
            <NavLink
              to={`/${mallLocation} `}
              className="navbar__mobile__link"
              onClick={() => setMobileMenu(!mobileMenu)}
              activeClassName="active"
            >
              Naslovnica
            </NavLink>
          </div>
          <div className="navbar__mobile__link__container">
            <NavLink
              to={`/${mallLocation}/trgovine`}
              className="navbar__mobile__link"
              activeClassName="active"
              onClick={() => setMobileMenu(!mobileMenu)}
            >
              Trgovine
            </NavLink>
          </div>
          <div className="navbar__mobile__link__container">
            <NavLink
              to={`/${mallLocation}/promocije`}
              className="navbar__mobile__link"
              activeClassName="active"
              onClick={() => setMobileMenu(!mobileMenu)}
            >
              Promocije
            </NavLink>
          </div>
          <div className="navbar__mobile__link__container">
            <NavLink
              to={`/${mallLocation}/novosti`}
              className="navbar__mobile__link"
              activeClassName="active"
              onClick={() => setMobileMenu(!mobileMenu)}
            >
              Novosti
            </NavLink>
          </div>
        </div>
        <div className="navbar__mobile__search__container">
          <div className="navbar__mobile__search">
            <input
              type="text"
              placeholder="Pretraži trgovine, novosti, promocije..."
              onChange={(e) => setSearch(e.target.value)}
            />
            <button className="navbar__search__button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={0.5}
                stroke="currentColor"
                className="icon"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                />
              </svg>
            </button>
          </div>

          <div className="navbar__link__dropdown">
            <NavLink
              className="navbar__link__dropdown__item promotion title"
              to={`/${mallLocation}/trgovine`}
            >
              <NavLink
                className="dropdown__link"
                to={`/${mallLocation}/trgovine/`}
              >
                Trgovine
              </NavLink>
            </NavLink>
            {stores?.map((store) => {
              if (store?.title.toLowerCase().includes(search.toLowerCase()))
                return (
                  <NavLink
                    className="navbar__link__dropdown__item"
                    to={`/${mallLocation}/trgovine/${store?._id}`}
                    onClick={() => setMobileMenu(!mobileMenu)}
                  >
                    <NavLink
                      className="dropdown__link"
                      to={`/${mallLocation}/trgovine/${store?._id}`}
                      onClick={() => setMobileMenu(!mobileMenu)}
                    >
                      {store?.title}
                    </NavLink>
                    <NavLink
                      className="logo__container"
                      to={`/${mallLocation}/trgovine/${store?._id}`}
                      onClick={() => setMobileMenu(!mobileMenu)}
                    >
                      <img src={store?.logo} alt="logo" />
                    </NavLink>
                  </NavLink>
                );
            })}
            <NavLink
              className="navbar__link__dropdown__item promotion title"
              to={`/${mallLocation}/promocije`}
            >
              <NavLink
                className="dropdown__link"
                to={`/${mallLocation}/promocije/`}
                onClick={() => setMobileMenu(!mobileMenu)}
              >
                Promocije
              </NavLink>
            </NavLink>
            {promotions?.map((promotion) => {
              if (promotion?.title.toLowerCase().includes(search.toLowerCase()))
                return (
                  <NavLink
                    className="navbar__link__dropdown__item promotion"
                    to={`/${mallLocation}/promocije/${promotion?._id}`}
                    onClick={() => setMobileMenu(!mobileMenu)}
                  >
                    <NavLink
                      className="dropdown__link"
                      to={`/${mallLocation}/promocije/${promotion?._id}`}
                      onClick={() => setMobileMenu(!mobileMenu)}
                    >
                      {promotion?.title}
                    </NavLink>
                    <NavLink
                      className="logo__container"
                      to={`/${mallLocation}/promocije/${promotion?.images[0]}`}
                      onClick={() => setMobileMenu(!mobileMenu)}
                    >
                      <img src={promotion?.logo} alt="logo" />
                    </NavLink>
                  </NavLink>
                );
            })}
            <NavLink
              className="navbar__link__dropdown__item promotion title"
              to={`/${mallLocation}/novosti`}
              onClick={() => setMobileMenu(!mobileMenu)}
            >
              <NavLink
                className="dropdown__link"
                to={`/${mallLocation}/novosti/`}
                onClick={() => setMobileMenu(!mobileMenu)}
              >
                Novosti
              </NavLink>
            </NavLink>
            {allNews?.map((news) => {
              if (news?.title.toLowerCase().includes(search.toLowerCase()))
                return (
                  <NavLink
                    className="navbar__link__dropdown__item promotion"
                    to={`/${mallLocation}/novosti/${news?._id}`}
                    onClick={() => setMobileMenu(!mobileMenu)}
                  >
                    <NavLink
                      className="dropdown__link"
                      to={`/${mallLocation}/novosti/${news?._id}`}
                      onClick={() => setMobileMenu(!mobileMenu)}
                    >
                      {news?.title}
                    </NavLink>
                    <NavLink
                      className="logo__container "
                      onClick={() => setMobileMenu(!mobileMenu)}
                      to={`/${mallLocation}/novosti/${news?._id}`}
                    >
                      {news?.images[0].includes(".mp4") ? (
                        <img src={news?.images[1]} alt="logo" />
                      ) : (
                        <img src={news?.images[0]} alt="logo" />
                      )}
                    </NavLink>
                  </NavLink>
                );
            })}
          </div>
        </div>

        <div className="bottom">© 2023. Park & Shop. Sva prava pridržana.</div>
      </div>
    </div>
  );
};

export default Header;
