import React, { useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import "../styles/Footer.scss";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import twitter from "../assets/images/twitter_logo.png";
import facebook from "../assets/images/facebook_logo.png";
import instagram from "../assets/images/instagram_logo.png";
import tiktok from "../assets/images/tiktok_logo.png";
import pinterest from "../assets/images/pinterest_logo.png";
import { Context } from "../App";
const Footer = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyCkYyBmf1DavGR5licohv8jn-HmdZD6XCk",
  });

  const mallLocation = useLocation().pathname.split("/")[1];

  const { setNewsletterPopUp } = useContext(Context);

  return (
    <>
      <div className="hero__socials ">
        <hr />
        <div className="hero__socials__container">
          <a
            href="https://www.facebook.com/profile.php?id=100087764918874"
            target="_blank"
            rel="noreferrer"
          >
            <img src={facebook} alt="facebook" />
          </a>
          <a
            href="https://www.instagram.com/park_shop_sesvete/?hl=hr"
            target="_blank"
            rel="noreferrer"
          >
            <img src={instagram} alt="instagram" />
          </a>
        </div>
        <hr />
      </div>
      <div className="footer">
        <div className="footer__container">
          <div className="footer__box">
            <h2> O nama</h2>
            <hr />

            <div className="footer__box__content">
              <p className="footer__box__content__text">
                Centar je otvoren 2023. godine, a u njemu se nalazi 15 trgovina
                te ugostiteljski objekt.
              </p>
              <p className="footer__box__content__text">
                E-mail: info@parkandshop.hr
              </p>
              <p className="footer__box__content__text">
                Telefon: +385 95 9161 097
              </p>
            </div>
          </div>
          <div className="footer__box">
            <h2> Poslovni korisnici</h2>
            <hr />

            <div className="footer__box__content">
              <Link
                to={"/" + mallLocation + "/zakup-prostora"}
                className="footer__box__content__link"
              >
                Zakup prostora
              </Link>
              <Link to="/sesvete/" className="footer__box__content__link">
                Oglašavanje u centru
              </Link>
            </div>
          </div>

          <div className="footer__box">
            <h2>Kako do nas?</h2>
            <hr />

            <div className="footer__box__content">
              <p className="footer__box__content__text">
                Adresa: 144. brigade Hrvatske vojske 3-5, 10360, Sesvete
              </p>
              <div className="footer__box__content__map">
                {isLoaded ? (
                  <GoogleMap
                    mapContainerStyle={{ width: "100%", height: "100%" }}
                    zoom={15}
                    center={{ lat: 45.815179684115016, lng: 16.10527402309837 }}
                  >
                    <Marker
                      position={{
                        lat: 45.815179684115016,
                        lng: 16.10527402309837,
                      }}
                    />
                  </GoogleMap>
                ) : (
                  <p></p>
                )}
              </div>
            </div>
          </div>

          <div className="footer__box">
            <h2> Newsletter</h2>
            <hr />
            <div className="footer__box__content">
              <p>
                Prijavi se na naš newsletter i primaj obavijesti o novostima i
                akcijama u centru.
              </p>
              <button className="btn" onClick={() => setNewsletterPopUp(true)}>
                <div className="btn__front">
                  <span> Idi na prijavu </span>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div className="bottom">
          <p>© 2023 Park&Shop. Sva prava pridržana.</p>
        </div>
      </div>
    </>
  );
};

export default Footer;
