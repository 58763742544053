import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import mallService from "./mallService";

export const getMall = createAsyncThunk("mall/get-mall", async (thunkAPI) => {
  try {
    return await mallService.getAllMall();
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getAMall = createAsyncThunk(
  "mall/get-a-mall",
  async (location, thunkAPI) => {
    try {
      return await mallService.getMall(location);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const resetState = createAction("Reset_all");

const initialState = {
  allMalls: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};

export const MallSlice = createSlice({
  name: "mall",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMall.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMall.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.allMalls = action.payload;
      })
      .addCase(getMall.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })

      .addCase(getAMall.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAMall.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.mall = action.payload;
      })
      .addCase(getAMall.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(resetState, () => initialState);
  },
});

export default MallSlice.reducer;
