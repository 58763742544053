import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import leaseRequestsService from "./leaseRequestsService";

export const resetState = createAction("Reset_all");

export const createLeaseRequest = createAsyncThunk(
  "leaseRequests/createLeaseRequest",
  async (data, { rejectWithValue }) => {
    try {
      const response = await leaseRequestsService.createALeaseRequest(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
};

const leaseRequestsSlice = createSlice({
  name: "lease-requests",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resetState, (state) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = false;
        state.message = "";
      })
      .addCase(createLeaseRequest.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = "";
      })
      .addCase(createLeaseRequest.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.message = payload.message;
      })

      .addCase(createLeaseRequest.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = payload.message;
      });
  },
});

export default leaseRequestsSlice.reducer;
