import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./App.scss";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import Stores from "./pages/Stores";
import Store from "./pages/Store";
import Promotions from "./pages/Promotions";
import News from "./pages/News";
import Promotion from "./pages/Promotion";
import New from "./pages/New";
import LeaseRequest from "./pages/LeaseRequest";
import Malls from "./pages/Malls";
import logo from "./assets/images/logo.png";

export const Context = React.createContext();

function App() {
  const [cookiesPopUp, setCookiesPopUp] = useState(true);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [newsletterPopUp, setNewsletterPopUp] = useState(false);

  useEffect(() => {
    const originalTitle = document.title; // Store the original title

    // Update the page title
    document.title = "Park & Shop Sesvete";

    // Change the favicon (React logo)
    const link =
      document.querySelector("link[rel='icon']") ||
      document.createElement("link");
    link.rel = "icon";
    link.href = logo; // Replace with the path to your new favicon
    document.head.appendChild(link);

    // Restore the original title when the component unmounts
    return () => {
      document.title = originalTitle;
    };
  }, []);

  return (
    <>
      <Context.Provider
        value={{
          cookiesPopUp,
          setCookiesPopUp,
          mobileMenu,
          setMobileMenu,
          newsletterPopUp,
          setNewsletterPopUp,
        }}
      >
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Malls />} />
            <Route path="/sesvete" element={<Layout />}>
              <Route path="/sesvete/*" element={<h1> Not Found </h1>} />
              <Route index element={<Home />} />
              <Route path="/sesvete/trgovine" element={<Stores />} />
              <Route path="/sesvete/trgovine/:id" element={<Store />} />
              <Route path="/sesvete/promocije" element={<Promotions />} />
              <Route path="/sesvete/novosti" element={<News />} />
              <Route path="/sesvete/promocije/:id" element={<Promotion />} />
              <Route path="/sesvete/novosti/:id" element={<New />} />
              <Route
                path="/sesvete/zakup-prostora"
                element={<LeaseRequest />}
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </Context.Provider>
    </>
  );
}

export default App;
