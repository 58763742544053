import axios from "axios";
import { base_url } from "../../utils/baseUrl";
import { config } from "../../utils/axiosconfig";

const getAllStores = async () => {
  const response = await axios.get(`${base_url}stores/`, config);

  return response.data;
};

const getStore = async (id) => {
  const response = await axios.get(`${base_url}stores/${id}`, config);
  return response.data;
};

const storeService = {
  getAllStores,
  getStore,
};

export default storeService;
