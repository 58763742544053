import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import "../styles/LeaseRequest.scss";
import { BsArrowRight } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { createLeaseRequest } from "../features/leaseRequests/leaseRequestsSlice";

const validationSchema = yup.object({
  firstname: yup.string("Unesite ime").required("Ime je obavezno"),
  lastname: yup.string("Unesite prezime").required("Prezime je obavezno"),
  email: yup
    .string("Unesite email")
    .email("Unesite ispravan email")
    .required("Email je obavezan"),
  phone: yup
    .string("Unesite broj telefona")
    .required("Broj telefona je obavezan"),
  message: yup.string("Unesite poruku").required("Poruka je obavezna"),
  company: yup.string("Unesite naziv kompanije"),

  leaseType: yup
    .string("Unesite tip zakupa")
    .required("Tip zakupa je obavezan"),
});

const LeaseRequest = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      message: "",
      company: "",
      location: "Sesvete",
      leaseType: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(createLeaseRequest(values));
      alert("Uspješno ste poslali zahtjev za zakup");
    },
  });

  return (
    <div className="leaseRequest page">
      <div className="leaseRequest__container">
        <h3> Želite zakupiti prostor u našem centru?</h3>
        <p>
          {" "}
          Ako ste zainteresirani za zakup prostora, molimo popunite formu. Naš
          tim će Vas kontaktirati u najkraćem mogućem roku kako bi Vam pružio
          sve potrebne informacije. Ukoliko imate dodatnih pitanja, slobodno nas
          kontaktirajte na broj telefona ili putem emaila
        </p>
        <form className="leaseRequest__form" onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="leaseRequest__form__input">
              <label htmlFor="firstname">Ime</label>
              <input
                id="firstname"
                name="firstname"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstname}
              />
              {formik.touched.firstname && formik.errors.firstname ? (
                <div className="leaseRequest__form__error">
                  {formik.errors.firstname}
                </div>
              ) : null}
            </div>
            <div className="leaseRequest__form__input">
              <label htmlFor="lastname">Prezime</label>
              <input
                id="lastname"
                name="lastname"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastname}
              />
              {formik.touched.lastname && formik.errors.lastname ? (
                <div className="leaseRequest__form__error">
                  {formik.errors.lastname}
                </div>
              ) : null}
            </div>
          </div>

          <div className="row">
            <div className="leaseRequest__form__input">
              <label htmlFor="email">Email</label>
              <input
                id="email"
                name="email"
                type="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="leaseRequest__form__error">
                  {formik.errors.email}
                </div>
              ) : null}
            </div>
            <div className="leaseRequest__form__input">
              <label htmlFor="phone">Broj telefona</label>
              <input
                id="phone"
                name="phone"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone}
              />
              {formik.touched.phone && formik.errors.phone ? (
                <div className="leaseRequest__form__error">
                  {formik.errors.phone}
                </div>
              ) : null}
            </div>
          </div>

          <div className="leaseRequest__form__input">
            <label htmlFor="company">Naziv firme</label>
            <input
              id="company"
              name="company"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.company}
            />
            {formik.touched.company && formik.errors.company ? (
              <div className="leaseRequest__form__error">
                {formik.errors.company}
              </div>
            ) : null}
          </div>
          <div className="row">
            <div className="leaseRequest__form__input">
              <label htmlFor="location">Lokacija</label>
              <select
                id="location"
                name="location"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.location}
              >
                <option value="Sesvete">Sesvete</option>
              </select>
            </div>

            <div className="leaseRequest__form__input">
              <label htmlFor="leaseType">Tip zakupa</label>
              <select
                id="leaseType"
                name="leaseType"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.leaseType}
              >
                <option value="Zakup fiksnog prostora">
                  Zakup fiksnog prostora
                </option>
                <option value="Zakup vanjskog prostora">
                  Zakup vanjskog prostora
                </option>
              </select>
              {formik.touched.leaseType && formik.errors.leaseType ? (
                <div className="leaseRequest__form__error">
                  {formik.errors.leaseType}
                </div>
              ) : null}
            </div>
          </div>
          <div className="leaseRequest__form__input">
            <label htmlFor="message">Poruka</label>
            <textarea
              id="message"
              name="message"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.message}
            />
            {formik.touched.message && formik.errors.message ? (
              <div className="leaseRequest__form__error">
                {formik.errors.message}
              </div>
            ) : null}
          </div>

          <button className="btn" type="submit">
            <div className="btn__front">
              <span>Pošalji zahtjev</span>
              <BsArrowRight className="icon" />
            </div>
          </button>
        </form>
      </div>
    </div>
  );
};

export default LeaseRequest;
