import React, { useEffect } from "react";
import "../styles/Card.scss";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import moment from "moment";
import Aos from "aos";
import "aos/dist/aos.css";

const Card = ({ item, type }) => {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);

  return (
    <Link
      className="card"
      to={"/sesvete/" + type + "/" + item?._id}
      data-aos="fade-up"
      data-aos-duration="500"
      data-aos-once="false"
    >
      <div className="card__image">
        {item?.images[0].includes("mp4") ? (
          <img src={item?.images[1]} alt={item?.title} />
        ) : (
          <img src={item?.images[0]} alt={item?.title} />
        )}
      </div>
      <div className="card__content">
        <h3>{item?.title}</h3>
        <div className="card__content__date">
          {moment(item?.dateStart).format("DD.MM.YYYY")}
          {item?.dateEnd ? (
            <span> - {moment(item?.dateEnd).format("DD.MM.YYYY")}</span>
          ) : (
            ""
          )}
        </div>

        <div className="button">
          <Link className="btn" to={"/sesvete/" + type + "/" + item?._id}>
            <div className="btn__front">Pogledajte više</div>
          </Link>
        </div>
      </div>
    </Link>
  );
};

export default Card;
