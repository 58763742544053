import axios from "axios";
import { base_url } from "../../utils/baseUrl";
import { config } from "../../utils/axiosconfig";

const createALeaseRequest = async (data) => {
  const response = await axios.post(`${base_url}lease-requests`, data, config);

  return response.data;
};

const leaseRequestsService = {
  createALeaseRequest,
};

export default leaseRequestsService;
