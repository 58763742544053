import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import newsLtterService from "./newsLetterService";

export const resetState = createAction("Reset_all");

export const registerASubscriber = createAsyncThunk(
  "newsletter/registerASubscriber",
  async (data, { rejectWithValue }) => {
    try {
      const response = await newsLtterService.registerSubscriber(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const sendVerificationMail = createAsyncThunk(
  "newsletter/sendVerificationMail",
  async (data, { rejectWithValue }) => {
    try {
      const response = await newsLtterService.sendVerificationEmail(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
};

const newsLetterSlice = createSlice({
  name: "newsletter",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(resetState, (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    });
    builder.addCase(registerASubscriber.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    });
    builder.addCase(registerASubscriber.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
      state.message = payload.message;
    });
    builder.addCase(registerASubscriber.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.message = payload.message;
    });
    builder.addCase(sendVerificationMail.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    });
    builder.addCase(sendVerificationMail.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
      state.message = payload.message;
    });
    builder.addCase(sendVerificationMail.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.message = payload.message;
    });
  },
});

export default newsLetterSlice.reducer;
