import React, { useState, useEffect } from "react";
import { getAStore } from "../features/stores/storeSlice";
import "../styles/Store.scss";
import "../styles/Sections.scss";
import { useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import moment from "moment";
import { BsArrowRight } from "react-icons/bs";
import Carousel from "../components/Carousel";
import { getPromotion } from "../features/promotions/promotionsSlice";
import Aos from "aos";
import "aos/dist/aos.css";
const Store = () => {
  const dispatch = useDispatch();
  const { store, isLoading, isError, isSuccess } = useSelector(
    (state) => state?.store
  );
  const { allPromotions: promotions } = useSelector(
    (state) => state?.promotions
  );
  const id = useLocation().pathname.split("/")[3];
  const [activeHeroItem, setActiveHeroItem] = useState(0);
  const [activePromotion, setActivePromotion] = useState(0);

  useEffect(() => {
    dispatch(getAStore(id));
    dispatch(getPromotion());
    window.scrollTo(0, 0);
    Aos.init({ duration: 1500 });
  }, []);

  const nextSlide = () => {
    setActiveHeroItem((prev) => {
      if (prev === store?.images?.length - 1) {
        return 0;
      } else {
        return prev + 1;
      }
    });
  };

  const prevSlide = () => {
    setActiveHeroItem((prev) => {
      if (prev === 0) {
        return store?.images?.length - 1;
      } else {
        return prev - 1;
      }
    });
  };

  const nextPromotion = () => {
    setActivePromotion((prev) => {
      if (prev === store?.promotions?.length - 1) {
        return 0;
      } else {
        return prev + 1;
      }
    });
  };

  const prevPromotion = () => {
    setActivePromotion((prev) => {
      if (prev === 0) {
        return store?.promotions?.length - 1;
      } else {
        return prev - 1;
      }
    });
  };

  useEffect(() => {
    dispatch(getAStore(id));
  }, [id]);

  return (
    <div className="store page">
      <div className="store__page__header">
        <div className="rectangle"></div>

        <div className="store__page__header__content">
          <div className="store__page__header__content__left">
            <div className="images__container">
              {store?.images?.map((image, index) => (
                <img
                  src={image}
                  alt={store?.title}
                  className={index === activeHeroItem ? "active" : ""}
                />
              ))}

              <div className="images__container__buttons">
                {store?.images?.map((image, index) => (
                  <button
                    className={index === activeHeroItem ? "active" : ""}
                    onClick={() => {
                      setActiveHeroItem(index);
                    }}
                  ></button>
                ))}
              </div>
            </div>
          </div>
          <div
            className="logo"
            data-aos="fade-left"
            data-aos-duration="1500"
            data-aos-once="false"
          >
            <img src={store?.logo} alt={store?.title} />
          </div>

          <div
            className="store__page__header__content__right"
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-once="false"
          >
            <h3>{store?.title}</h3>
            <div className="content">
              <p>Telefon: </p>
              <span>{store?.phone}</span>
            </div>
            <div className="content">
              <p>E-mail: </p>
              <span>{store?.email}</span>
            </div>
            <div className="content">
              <p>Web mjesto: </p>
              <a
                href={`http://${store?.websiteLink}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {store?.websiteLink}
              </a>
            </div>

            <div className="working__hours">
              <p>Ponedjeljak - Subota: {store?.workingHours?.ponedjeljak}</p>
              <p>Nedjelja: {store?.workingHours?.nedjelja}</p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="location__image"
        style={store?.locationImage ? { display: "flex" } : { display: "none" }}
      >
        <img src={store?.locationImage} alt={store?.title} />
      </div>

      <div
        className="content__container"
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-once="false"
      >
        <h3> {store?.title} </h3>
        <div
          className="description"
          dangerouslySetInnerHTML={{ __html: store?.description }}
        ></div>
      </div>

      {store?.promotions?.length > 0 ? (
        <div className="promotions__container">
          <div className="promotions__c">
            {store?.promotions?.map((item, index) => (
              <Link
                className="promotions__card big"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-once="false"
                key={index}
                to={"/sesvete/promocije/" + item?._id}
              >
                <div className="promotions__card__image">
                  <img src={item?.images[0]} alt={item?.title} />
                </div>
                <div className="promotions__card__content">
                  <h3>{item?.title}</h3>
                  <div className="card__content__date">
                    {moment(item?.dateStart).format("DD.MM.YYYY")}
                    {item?.dateEnd ? (
                      <span>
                        {" "}
                        - {moment(item?.dateEnd).format("DD.MM.YYYY")}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Store;
