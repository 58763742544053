import axios from "axios";
import { base_url } from "../../utils/baseUrl";
import { config } from "../../utils/axiosconfig";

const getAllMall = async () => {
  const response = await axios.get(`${base_url}mall/`);

  return response.data;
};

const getMall = async (location) => {
  const response = await axios.get(`${base_url}mall/${location}`, config);

  return response.data;
};

const mallService = {
  getAllMall,
  getMall,
};

export default mallService;
