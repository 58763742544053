import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../styles/CookieComponent.scss";

const CookieComponent = () => {
  const [cookieActive, setCookieActive] = useState(false);

  const generateCookieId = () => {
    // Generate a unique ID for the cookie value
    const id = Math.random().toString(36).substring(2);
    return id;
  };

  const setCookie = () => {
    const cookieId = generateCookieId();
    document.cookie = `__c=${cookieId}; expires=N/A; path=/`;
  };

  const getCookie = (cookieName) => {
    const name = cookieName + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(";");

    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === " ") {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }

    return "";
  };

  useEffect(() => {
    if (getCookie("__c") === "") {
      setCookieActive(true);
    }
  }, []);

  return (
    <div className={cookieActive ? "cookies active" : "cookies"}>
      <div className="cookie__text">
        <h1 className="title">
          Park & Shop koristi kolačiće kako bi vama pružili bolje korisničko
          iskustvo.
        </h1>
        <p>
          Uz osnovne kolačiće, ovo web-mjesto koristi i kolačiće prve i treće
          strane koji omogućavaju poboljšanje njegovih značajki i sadržaja te
          analizu njegove upotrebe i u marketinške svrhe. Klikom na „Prihvati“
          pristajete na pohranu svih kolačića na svom uređaju. Ako želite
          omogućiti samo pojedine kolačiće, kliknite na „Upravljanje
          preferencama“.
        </p>
        <div className="cookie__link">
          <Link to="/sesvete">Pravila privatnosti</Link>
        </div>
      </div>

      <div className="cookie__buttons">
        <button
          onClick={() => {
            setCookie();
            setCookieActive(false);
          }}
        >
          Prihvati kolačiće
        </button>
      </div>
    </div>
  );
};

export default CookieComponent;
