import React, { useState, useEffect, useRef } from "react";
import "../styles/Home.scss";
import "../styles/Sections.scss";
import { getStores } from "../features/stores/storeSlice";
import { getAMall } from "../features/malls/mallSlice";
import { getNews } from "../features/news/newsSlice";
import { getPromotion } from "../features/promotions/promotionsSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { GrLinkPrevious, GrLinkNext } from "react-icons/gr";
import { BsArrowRight, BsSearch } from "react-icons/bs";
import Marquee from "react-fast-marquee";
import twitter from "../assets/images/twitter_logo.png";
import facebook from "../assets/images/facebook_logo.png";
import instagram from "../assets/images/instagram_logo.png";
import tiktok from "../assets/images/tiktok_logo.png";
import pinterest from "../assets/images/pinterest_logo.png";
import moment from "moment";
import MiniCarousel from "../components/MiniCarousel";
import Aos from "aos";
import "aos/dist/aos.css";
import Carousel from "../components/Carousel";

const Home = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [heroItems, setHeroItems] = useState([]);
  const [activeHeroItem, setActiveHeroItem] = useState(0);
  const mallLocation = location.pathname.split("/")[1];
  const [activePromotion, setActivePromotion] = useState(0);
  const [caffeBar, setCaffeBar] = useState([]);
  const [activeStore, setActiveStore] = useState(0);
  const [activeNews, setActiveNews] = useState(0);
  const [sortedPromotions, setSortedPromotions] = useState([]);
  const [isScrolled, setIsScrolled] = useState(false);

  const widthOfScreen = window.innerWidth;

  //save me to a variable scroll position
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 100);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const prevStore = () => {
    setActiveStore((prev) => {
      if (prev === 0) {
        return stores.length - 1;
      } else {
        return prev - 1;
      }
    });

    if (activeStore === 0) {
      setActiveStore(stores.length - 1);
    } else {
      setActiveStore(activeStore - 1);
    }
  };

  const nextStore = () => {
    setActiveStore((prev) => {
      if (prev === stores.length - 1) {
        return 0;
      } else {
        return prev + 1;
      }
    });
  };

  const prevPromotion = () => {
    setActivePromotion((prev) => {
      if (prev === 0) {
        return promotions.length - 1;
      } else {
        return prev - 1;
      }
    });
  };

  const nextPromotion = () => {
    setActivePromotion((prev) => {
      if (prev === promotions.length - 1) {
        return 0;
      } else {
        return prev + 1;
      }
    });
  };

  const prevNews = () => {
    setActiveNews((prev) => {
      if (prev === 0) {
        return news.length - 1;
      } else {
        return prev - 1;
      }
    });
  };

  const nextNews = () => {
    setActiveNews((prev) => {
      if (prev === news.length - 1) {
        return 0;
      } else {
        return prev + 1;
      }
    });
  };

  useEffect(() => {
    Aos.init({ duration: 1700 });
    window.scrollTo(0, 0);
  }, []);

  const promotionRef = useRef(null);

  const {
    allMalls: malls,
    isLoading: isLoadingMall,
    isError: isErrorMall,
    isSuccess: isSuccessMall,
    mall: mall,
  } = useSelector((state) => state?.mall);
  const {
    stores: stores,
    isLoading: isLoadingStore,
    isError: isErrorStore,
    isSuccess: isSuccessStore,
  } = useSelector((state) => state?.store);
  const {
    allNews: news,
    isLoading: isLoadingNews,
    isError: isErrorNews,
    isSuccess: isSuccessNews,
  } = useSelector((state) => state?.news);
  const {
    allPromotions: promotions,
    isLoading: isLoadingPromotion,
    isError: isErrorPromotion,
    isSuccess: isSuccessPromotion,
  } = useSelector((state) => state?.promotions);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getAMall(mallLocation));
    dispatch(getStores());
    dispatch(getNews());
    dispatch(getPromotion());
  }, []);

  useEffect(() => {
    if (isSuccessStore) {
      for (let i = 0; i < stores?.length; i++) {
        if (stores[i].title.toLowerCase().includes("caffe bar")) {
          setCaffeBar(stores[i]);
        }
      }
    }

    if (isSuccessStore) {
      // sort stores by title first letter
      const sortedStores = [...stores].sort((a, b) => {
        if (a.title[0] > b.title[0]) {
          return 1;
        } else {
          return -1;
        }
      });
    }
  }, [stores, isSuccessStore]);

  useEffect(() => {
    if (isSuccessPromotion && isSuccessNews) {
      // Update heroItems with promotions and set type to "promotion"
      const updatedHeroItems = promotions.map((item) => ({
        ...item,
        type: "promocije",
      }));

      // Add news to a temporary array and set type to "news"
      const tempArray = news.map((item) => ({
        ...item,
        type: "novosti",
      }));
      // Merge tempArray with updatedHeroItems
      const mergedItems = [...updatedHeroItems, ...tempArray];

      // Sort mergedItems by date
      const sortedItems = mergedItems.sort(
        (a, b) => new Date(b.dateStart) - new Date(a.dateStart)
      );

      // Move video item to the beginning of the array, if present
      const videoIndex = sortedItems.findIndex((item) =>
        item.images[0]?.includes("mp4")
      );
      if (videoIndex !== -1) {
        const videoItem = sortedItems.splice(videoIndex, 1);
        sortedItems.unshift(videoItem[0]);
      }

      // Update heroItems with the final sorted and modified items
      setHeroItems(sortedItems);

      // sort the promotions and save it to variable sortedPromotions
      setSortedPromotions(
        [...promotions]
          .sort((a, b) => new Date(b.dateStart) - new Date(a.dateStart))
          .slice(0, 5) // takes the first 4 elements
      );
    }
  }, [isSuccessPromotion, isSuccessNews]);

  const nextSlide = () => {
    setActiveHeroItem((prev) => {
      if (prev === heroItems.length - 1) {
        return 0;
      } else {
        return prev + 1;
      }
    });
  };

  const prevSlide = () => {
    setActiveHeroItem((prev) => {
      if (prev === 0) {
        return heroItems.length - 1;
      } else {
        return prev - 1;
      }
    });
  };

  return (
    <div className="page home">
      <div className={isScrolled ? "hero scrolled" : "hero"}>
        <div className="images__slider">
          {heroItems?.map((item, index) => (
            <div
              className={
                index === activeHeroItem
                  ? "hero__item__content active"
                  : "hero__item__content"
              }
              key={index}
            >
              <div className="hero__item__content__text">
                <h3>{item?.title}</h3>
                <div
                  dangerouslySetInnerHTML={{ __html: item?.description }}
                ></div>
                <Link
                  className="btn"
                  to={"/sesvete/" + item?.type + "/" + item?._id}
                >
                  <div className="btn__front">
                    <span>Pogledajte više </span>
                    <BsArrowRight className="icon" />
                  </div>
                </Link>
              </div>{" "}
            </div>
          ))}
          {heroItems?.map((item, index) =>
            item?.images[0].includes("mp4") ? (
              <div className="hero__item__image">
                <video
                  src={item?.images[0]}
                  alt={item?.title}
                  className={index === activeHeroItem ? "active" : ""}
                  loop={true}
                  autoPlay={true}
                  muted={true}
                />
              </div>
            ) : (
              <div className="hero__item__image">
                <img
                  src={item?.images[0]}
                  alt={item?.title}
                  className={index === activeHeroItem ? "active" : ""}
                />
              </div>
            )
          )}

          <div className="slider__buttons">
            <button
              className="slider__button"
              onClick={prevSlide}
              data-aos="slide-right"
              data-aos-duration="500"
              data-aos-once="false"
              data-aos-delay="500"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={0.5}
                stroke="currentColor"
                className="w-12 h-12"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 19.5L8.25 12l7.5-7.5"
                />
              </svg>{" "}
            </button>
            <button
              className="slider__button"
              onClick={nextSlide}
              data-aos="slide-left"
              data-aos-delay="500"
              data-aos-duration="500"
              data-aos-once="false"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={0.5}
                stroke="currentColor"
                className="w-12 h-12"
                style={{ transform: "rotate(180deg)" }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 19.5L8.25 12l7.5-7.5"
                />
              </svg>{" "}
            </button>
          </div>
        </div>
      </div>

      <div className="cards ">
        <div
          className="card__title"
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-once="false"
        >
          <h2>Događaji</h2>
          <h4>Novosti u Centru</h4>
          <p>Provjerite sve događaje i novosti u centru. </p>
        </div>

        <Carousel items={news} type={"novosti"} />
      </div>

      <div className="promotions__container">
        <div className="promotions__c">
          {sortedPromotions?.map((item, index) => (
            <Link
              className={
                index === sortedPromotions.length - 2
                  ? "promotions__card big"
                  : "promotions__card"
              }
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-once="false"
              key={index}
              to={"/sesvete/promocije/" + item?._id}
            >
              <div className="promotions__card__image">
                <img src={item?.images[0]} alt={item?.title} />
              </div>
              <div className="promotions__card__content">
                <h3>{item?.title}</h3>
                <div className="card__content__date">
                  {moment(item?.dateStart).format("DD.MM.YYYY")}
                  {item?.dateEnd ? (
                    <span> - {moment(item?.dateEnd).format("DD.MM.YYYY")}</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Link>
          ))}
        </div>

        <Link
          className="btn c__btn"
          to="/sesvete/promocije"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-once="false"
        >
          <div className="btn__front">
            <span>Pogledajte više promocija</span>
          </div>
        </Link>
      </div>

      <div className="caffe__container">
        <div className="caffe__images">
          {caffeBar?.images && caffeBar.images.length > 1 && (
            <img src={mall?.images[0]} alt="caffe1" />
          )}
        </div>

        <div className="caffe__info">
          <h2> Centar</h2>
          <h4> Park & Shop Sesvete</h4>

          <p>
            Prostor "Park&Shop-a" prostire se na više od 13 tisuća četvornih
            metara unutar kojih je smješteno 15 trgovina i jedan ugostiteljski
            objekt, hipermarket "Tommy" te raznoliku ponudu trgovina za uređenje
            doma, kreativne materijale i tekstil s brendovima kao što su TEDi,
            JYSK, veliki broj svjetskih brendova poput Hervis, KIK, Bipa,
            Deichmann, Takko, Mueller, Pepco, Sinsay i NKD te Pet Centar i
            Optika Anda. Za posjetitelje je osigurano 500 parkirališnih mjesta.
          </p>
        </div>
        <div className="caffe__drinks__container">
          {caffeBar?.images && caffeBar?.images.length > 1 ? (
            <>
              <div
                className="caffe__drink__box"
                key={0}
                data-aos={widthOfScreen < 1700 ? "fade-up" : "fade-right"}
                data-aos-duration="1500"
                data-aos-once="false"
              >
                <div className="caffe__drink__image">
                  <img src={caffeBar.images[0]} alt={`caffe${0}`} />
                </div>
                <div className="caffe__drink__info">
                  <h2>Ugostiteljstvo</h2>

                  <h4> Caffe Bar Comodo</h4>
                  <p>
                    Odličan ugositeljski objekt u kojem možete uživati u odmoru
                    od shoppinga.
                  </p>
                </div>
              </div>
              <div
                className="caffe__drink__box"
                key={1}
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-once="false"
              >
                <div className="caffe__drink__image">
                  <img src={mall?.images[1]} alt={`caffe${1}`} />
                </div>
                <div className="caffe__drink__info">
                  <h2>hipermarket</h2>
                  <h4> 15 trgovina</h4>
                  <p>
                    Trgovine za uređenje doma tr veliki svjetski brendovi kao
                    što su Hervis, KIK, Bipa, Deichmann, Takko, Mueller,
                    Pepco,...
                  </p>
                </div>
              </div>
              <div
                className="caffe__drink__box"
                key={2}
                data-aos={widthOfScreen < 1700 ? "fade-up" : "fade-left"}
                data-aos-duration="1500"
                data-aos-once="false"
              >
                <div className="caffe__drink__image">
                  <img src={mall?.images[2]} alt={`caffe${2}`} />
                </div>
                <div className="caffe__drink__info">
                  <h2>parking</h2>
                  <h4> 500 Parkirnih mjesta</h4>
                  <p>
                    Park & Shop Sesvete je za svoje kupce osigurao 500
                    parkinrnih mjesta.
                  </p>
                </div>
              </div>
            </>
          ) : (
            <div> </div>
          )}
        </div>
      </div>
      <div className="stores__display ">
        <div
          className="line"
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-once="false"
        ></div>
        <span className="text">promjenite trgovinu</span>

        <buttons className="buttons">
          <button className="carousel__button" onClick={prevStore}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={0.5}
              stroke="currentColor"
              className="w-12 h-12"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>{" "}
          </button>
          <button className="carousel__button" onClick={nextStore}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={0.5}
              stroke="currentColor"
              className="w-12 h-12"
              style={{ transform: "rotate(180deg)" }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>{" "}
          </button>
        </buttons>

        <div
          className="stores__display__left"
          data-aos={widthOfScreen < 1700 ? "fade-up" : "fade-right"}
          data-aos-duration="1500"
          data-aos-once="false"
        >
          <div className="logo__container">
            {stores.map((store, index) => {
              return (
                <img
                  src={store.logo}
                  alt="logo"
                  className={index === activeStore ? "active" : ""}
                  onClick={() => setActiveStore(index)}
                />
              );
            })}
          </div>

          {stores.map((store, index) => {
            return (
              <div
                className={
                  index === activeStore ? "store__info active" : "store__info"
                }
              >
                <h6>{stores[activeStore]?.title}</h6>
                <div
                  className="description"
                  dangerouslySetInnerHTML={{
                    __html: stores[activeStore]?.description,
                  }}
                ></div>
                <div className="working__hours">
                  <span>
                    Ponedjeljak - Subota:{" "}
                    {stores[activeStore]?.workingHours?.ponedjeljak}
                  </span>
                  <span>
                    Nedjelja: {stores[activeStore]?.workingHours?.nedjelja}
                  </span>
                </div>
                <button className="btn">
                  <div className="btn__front">
                    <span>Pogledajte više </span>
                    <BsArrowRight className="icon" />
                  </div>
                </button>
              </div>
            );
          })}
        </div>

        <div className="stores__display__right">
          {stores.map((store, index) => {
            return (
              <div
                className={
                  index === activeStore
                    ? " images__container  active"
                    : "images__container "
                }
                key={index}
              >
                <img
                  src={store.images[0]}
                  alt="store"
                  key={index}
                  data-aos={widthOfScreen < 1700 ? "fade-up" : "fade-left"}
                  data-aos-duration="1500"
                  data-aos-once="false"
                />
                <div
                  className=" rectangle rectangle__left "
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  data-aos-once="false"
                >
                  <img src={stores[activeStore]?.images[1]} alt="store" />
                </div>{" "}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Home;
