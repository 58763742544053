import React, { useState, useContext } from "react";
import {
  registerASubscriber,
  sendVerificationMail,
} from "../features/newsLetter/newsLetterSlice";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import "../styles/Newsletter.scss";
import { Context } from "../App";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Unesite ispravnu email adresu")
    .required("Email je obavezan"),
});

const Newsletter = () => {
  const dispatch = useDispatch();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const { newsletterPopUp, setNewsletterPopUp } = useContext(Context);

  const formik = useFormik({
    initialValues: {
      email: "",
      firstname: "",
      lastname: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (termsAccepted) {
        dispatch(registerASubscriber(values));
        setTimeout(() => {
          dispatch(sendVerificationMail(values));
        }, 1000);
        alert(
          " VAŽNO: Da biste dovršili prijavu, morate potvrditi svoju e-mail adresu klikom na link u e-mailu koji ćemo Vam poslati. Ako u narednih nekoliko minuta od prijave ne primite navedeni e-mail, molimo provjerite pretinac neželjene pošte."
        );
      } else {
        alert("Morate prihvatiti uvjete korištenja");
      }
    },
  });
  return (
    <div className={newsletterPopUp ? "newsletter active" : "newsletter "}>
      <button
        className="close"
        onClick={() => setNewsletterPopUp(false)}
      ></button>

      <form className="newsletter__form" onSubmit={formik.handleSubmit}>
        <h2> Novosti i promocije</h2>
        <h4>Prijavite se na naš newsletter</h4>

        <button className="x" onClick={() => setNewsletterPopUp(false)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <p className="text">
          Prijavom na naš newsletter prvi ćete saznati sve novosti vezane uz naš
          centar i naše trgovine.
        </p>

        <div className="form__box">
          <label htmlFor="name">E-mail</label>
          <input
            type="email"
            name="email"
            id="email"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.email}
          />
        </div>
        <div className="form__box">
          <label htmlFor="name">Ime (opcionalno)</label>
          <input
            type="text"
            name="firstname"
            id="firstname"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.firstname}
          />
        </div>
        <div className="form__box">
          <label htmlFor="name">Prezime (opcionalno)</label>
          <input
            type="text"
            name="lastname"
            id="lastname"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.lastname}
          />
        </div>

        <div className="agreement">
          <input
            type="checkbox"
            onChange={() => setTermsAccepted(!termsAccepted)}
          />
          <label htmlFor="agreement">
            Želim primati newsletter Park & Shop centra i potvrđujem da sam
            upoznat s Uvjetima obrade osobnih podataka
          </label>
        </div>

        <button className="btn">
          <div className="btn__front">
            <span>Prijavi se </span>
          </div>
        </button>
      </form>
    </div>
  );
};

export default Newsletter;
