import React, { useEffect, useState } from "react";
import "../styles/Promotions.scss";
import { useSelector, useDispatch } from "react-redux";
import { getNews } from "../features/news/newsSlice";
import Card from "../components/Card";
import Aos from "aos";

const Promotions = () => {
  const dispatch = useDispatch();
  const { allNews: news } = useSelector((state) => state?.news);

  useEffect(() => {
    dispatch(getNews());
    window.scrollTo(0, 0);
    Aos.init({ duration: 1500 });
  }, []);

  return (
    <div className="promotions page">
      <div
        className="page__title"
        data-aos="fade-up"
        data-aos-duration="500"
        data-aos-once="false"
        data-aos-offset="300"
      >
        <h2>Događaji</h2>
        <h4>Novosti u Centru</h4>
        <p>Provjerite sve događaje i novosti u centru. </p>
      </div>

      <div className="promotions__container">
        <div className="promotions__boxes ">
          {news && news.length > 0 ? (
            [...news]
              .sort((a, b) => new Date(b.dateStart) - new Date(a.dateStart))
              .map((item, index) => <Card item={item} type={"novosti"} />)
          ) : (
            <p>Trenutno nema novosti.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Promotions;
