import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Malls = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/sesvete");
  }, [navigate]);

  return <div>Malls</div>;
};

export default Malls;
