import axios from "axios";
import { base_url } from "../../utils/baseUrl";
import { config } from "../../utils/axiosconfig";

const registerSubscriber = async (data) => {
  const response = await axios.post(
    `${base_url}newsletter-users`,
    data,
    config
  );

  return response.data;
};

const sendVerificationEmail = async (data) => {
  const response = await axios.post(
    `${base_url}newsletter-users/send-verification-email`,
    data,
    config
  );

  return response.data;
};

const leaseRequestsService = {
  registerSubscriber,
  sendVerificationEmail,
};

export default leaseRequestsService;
