import { configureStore } from "@reduxjs/toolkit";
import storeReducer from "../features/stores/storeSlice";
import newsReducer from "../features/news/newsSlice";
import promotionsReducer from "../features/promotions/promotionsSlice";
import mallReducer from "../features/malls/mallSlice";
import leaseRequestReducer from "../features/leaseRequests/leaseRequestsSlice";
import newsLetterReducer from "../features/newsLetter/newsLetterSlice";

export const store = configureStore({
  reducer: {
    store: storeReducer,
    news: newsReducer,
    promotions: promotionsReducer,
    mall: mallReducer,
    leaseRequest: leaseRequestReducer,
    newsLetter: newsLetterReducer,
  },
});

export default store;
