import React, { useEffect, useRef, useState } from "react";
import Card from "./Card";
import "../styles/Sections.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

const Carousel = ({ items, type }) => {
  const carousel = useRef();
  const displayWidth = useRef();
  const [translate, setTranslate] = useState(0);

  const translateRight = () => {
    let carouselWidth = carousel.current.scrollWidth;
    let display = displayWidth.current.scrollWidth;

    if (
      translate - carouselWidth / items.length <
      (-1 * carouselWidth) / 2 - display / 2
    ) {
      setTranslate(0);
    } else {
      if (window.innerWidth > 1500) {
        let t = translate - 4 * (carouselWidth / items.length);
        if (t < (-1 * carouselWidth) / 2 - display / 2) {
          setTranslate(0);
        } else {
          setTranslate(translate - carouselWidth / items.length);
        }
      } else if (window.innerWidth < 1500 && window.innerWidth > 768) {
        let t = translate - (3 * carouselWidth) / items.length;
        if (t < (-1 * carouselWidth) / 2 - display / 2) {
          setTranslate(0);
        } else {
          setTranslate(translate - carouselWidth / items.length);
        }
      } else {
        setTranslate(translate - carouselWidth / items.length);
      }
    }
  };

  const translateLeft = () => {
    if (!(translate + carousel.current.scrollWidth / items.length > 0)) {
      setTranslate(translate + carousel.current.scrollWidth / items.length);
    }
  };

  return (
    <>
      <div className="buttons">
        <button
          className="carousel__button"
          onClick={translateLeft}
          data-aos="fade-left"
          data-aos-duration="1000"
          data-aos-once="false"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={0.5}
            stroke="currentColor"
            className="w-12 h-12"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 19.5L8.25 12l7.5-7.5"
            />
          </svg>
        </button>
        <button
          className="carousel__button"
          onClick={translateRight}
          data-aos="fade-right"
          data-aos-duration="1000"
          data-aos-once="false"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={0.5}
            stroke="currentColor"
            className="w-12 h-12"
            style={{ transform: "rotate(180deg)" }}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 19.5L8.25 12l7.5-7.5"
            />
          </svg>
        </button>
      </div>
      <div className="carousel" ref={displayWidth}>
        <div
          className="inner__carousel"
          style={{ transform: `translateX(${translate}px)` }}
          ref={carousel}
        >
          {items && items.length > 0 ? (
            [...items]
              .sort((a, b) => new Date(b.dateStart) - new Date(a.dateStart))
              .map((item, index) => (
                <Card item={item} key={index} type={type} />
              ))
          ) : (
            <p>Trenutno nema.</p>
          )}
        </div>
      </div>
    </>
  );
};

export default Carousel;
