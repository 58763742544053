import React, { useEffect, useState } from "react";
import "../styles/Promotions.scss";
import { useSelector, useDispatch } from "react-redux";
import { getPromotion } from "../features/promotions/promotionsSlice";
import Card from "../components/Card";
import Aos from "aos";
import "aos/dist/aos.css";

const Promotions = () => {
  const dispatch = useDispatch();
  const { allPromotions: promotions } = useSelector(
    (state) => state?.promotions
  );

  useEffect(() => {
    dispatch(getPromotion());
    window.scrollTo(0, 0);
    Aos.init({ duration: 1500 });
  }, []);

  return (
    <div className="promotions page">
      <div
        className="page__title"
        data-aos="fade-up"
        data-aos-duration="500"
        data-aos-once="false"
        data-aos-offset="300"
      >
        <h2>Popusti</h2>
        <h4>Promocije</h4>
        <p>
          Ovdje možete pronaći sve promocije koje su trenutno aktivne u
          trgovinama.
        </p>
      </div>

      <div className="promotions__container">
        <div className="promotions__boxes ">
          {promotions && promotions.length > 0 ? (
            [...promotions]
              .sort((a, b) => new Date(b.dateStart) - new Date(a.dateStart))
              .map((item, index) => <Card item={item} type={"promocije"} />)
          ) : (
            <p>Trenutno nema promocija.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Promotions;
